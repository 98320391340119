/* eslint-disable */

import axios from "axios"

axios.interceptors.request.use(config => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  config.headers["X-CSRF-TOKEN"] = csrfToken;
  return config;
});

export default axios;
