import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "link"]

  toggle(event) {
  	event.preventDefault()
    this.containerTarget.classList.toggle("is-active")
  }

  hide(event) {
  	if (this.element.contains(event.target) === false 
  		  && this.containerTarget.classList.contains("is-active")) {
  		this.containerTarget.classList.remove("is-active")
  	}
  }
}
