import { Controller } from "@hotwired/stimulus";
import { navigator } from '@hotwired/turbo'
import moment from "moment";

export default class extends Controller {
  static targets = ["currentDate", "venue"];
  static values = {
    prevDate: String,
    nextDate: String
  }

  changeVenue() {
    const location = window.location.toString();
    const oldId = this.data.get("venueId");
    const venueId = this.venueTarget.value;

    Turbo.visit(location.replace(`/${oldId}/`, `/${venueId}/`))
  }

  selectDate(event) {
    const selected = moment(event.detail.date.toLocaleDateString(),
                       "DD.MM.YYYY").format("YYYY-MM-DD")

    this.goToDate(selected)
  }

  goToDate(date) {
    const location = window.location.toString().split('?')[0] + `?date=${date}`

    Turbo.visit(location)
    navigator.history.push(new URL(location))
  }

  prev(event) {
    event.preventDefault()

    this.goToDate(this.prevDateValue)
  }

  next(event) {
    event.preventDefault()

    this.goToDate(this.nextDateValue)
  }
}
