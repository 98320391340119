import { Controller } from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ["select", "price", "start", "end", "payment", "withdraw"]

  selected(event) {
    const data = this.selectTarget.selectedOptions[0].dataset

    this.priceTarget.value = data.price
    this.startTarget.value = moment().format("DD.MM.YYYY")
    this.endTarget.value = moment().add(data.duration, 'days').format("DD.MM.YYYY")
  }

  withdraw(event) {
    if (event.target.checked) {
      this.paymentTarget.classList.add('is-hidden')
    } else {
      this.paymentTarget.classList.remove('is-hidden')
    }

  }
}
