import { Controller } from "@hotwired/stimulus"
import axios from "../lib/axios";

export default class extends Controller {
  static targets = ["checkbox", "label", "itemId", "master", "price", "pack"]
  static values = {
    client: String,
    group: String,
    venue: String,
    date: String,
    event: String
  }

  changeMaster(event) {
    if (!this.checkboxTarget.checked) { return }

    const params = {
      master: this.masterTarget.value
    }

    axios.put(`/dashboard/venues/${this.venueValue}/service_items/${this.itemIdTarget.value}`, params)
         .then(json => {

         });
  }

  toggle(event) {
    if (event.target.checked) {
      this.commitItem()
    } else {
      this.removeItem()
    }
  }

  commitItem() {
    const params = {
      client_id: this.clientValue,
      group_id: this.groupValue,
      date: this.dateValue,
      event_id: this.eventValue,
      master_id: this.masterTarget.value
    }

    axios.post(`/dashboard/venues/${this.venueValue}/service_items`, params)
         .then(json => {
           this.labelTarget.classList.add('has-text-weight-bold')
           this.itemIdTarget.value = json.data.id
           this.priceTarget.innerHTML = json.data.price
           if (json.data.price != null) {
             this.priceTarget.classList.remove('is-hidden')
           }
           if (json.data.pack_id != null) {
             this.packTarget.classList.remove('is-hidden')
           }
         });
  }

  removeItem() {
    axios.delete(`/dashboard/venues/${this.venueValue}/service_items/${this.itemIdTarget.value}`)
         .then(json => {
           this.labelTarget.classList.remove('has-text-weight-bold')
           this.priceTarget.classList.add('is-hidden')
           this.packTarget.classList.add('is-hidden')
         });
  }
}
