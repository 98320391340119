import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"]

  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit)
    this.containerTarget.querySelector('.modal-background').classList.add('scaleInCircle')
    this.containerTarget.querySelector('.modal-content').classList.add('scaleIn')
    document.body.classList.add('is-fixed');
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
    document.body.classList.remove('is-fixed');
  }

  close() {
    this.element.remove()

    document.getElementById('modal').src = undefined
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }
}
