import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Autocomplete {
  static targets = ["container", "input", "hidden", "hiddenType", "results"]

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    const textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim()
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    if (this.hasHiddenTypeTarget) {
      this.hiddenTypeTarget.value = selected.getAttribute("data-autocomplete-type")
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected }
      })
    )
  }

  clear() {
    this.inputTarget.value = ""
    if (this.hasHiddenTarget) this.hiddenTarget.value = ""
    if (this.hasHiddenTypeTarget) this.hiddenTypeTarget.value = ""
  }

  open() {
    if (this.resultsShown) return

    this.resultsShown = true
    this.containerTarget.setAttribute("aria-expanded", "true")
    this.containerTarget.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { action: "open", inputTarget: this.inputTarget, resultsTarget: this.resultsTarget }
      })
    )
  }

  close() {
    if (!this.resultsShown) return

    this.resultsShown = false
    this.inputTarget.removeAttribute("aria-activedescendant")
    this.containerTarget.setAttribute("aria-expanded", "false")
    this.containerTarget.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { action: "close", inputTarget: this.inputTarget, resultsTarget: this.resultsTarget }
      })
    )
  }

  get resultsShown() {
    return !this.containerTarget.hidden
  }

  set resultsShown(value) {
    this.containerTarget.hidden = !value
  }

}
