import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"
import ru from 'vanillajs-datepicker/locales/ru';

export default class extends Controller {
  static targets = ["field"]

  connect() {
    Object.assign(Datepicker.locales, ru);

    const picker = new Datepicker(this.fieldTarget, {
      autohide: true,
      format: "dd.mm.yyyy",
      language: "ru",
      weekStart: 1
    })
  }
}
