import { Controller } from "@hotwired/stimulus"
import axios from "../lib/axios";

export default class extends Controller {
  static targets = ["checkbox", "label", "itemId"]
  static values = {
    client: String,
    group: String,
    report: String,
    event: String,
    token: String
  }

  toggle(event) {
    if (event.target.checked) {
      this.commitItem()
    } else {
      this.removeItem()
    }
  }

  commitItem() {
    const params = {
      client: this.clientValue,
      group: this.groupValue,
      event: this.eventValue
    }

    axios.post(`/app/${this.tokenValue}/reports/${this.reportValue}/items/`, params)
         .then(json => {
           this.labelTarget.classList.add('has-text-weight-bold')
           this.itemIdTarget.value = json.data.id
         });
  }

  removeItem() {
    axios.delete(`/app/${this.tokenValue}/reports/${this.reportValue}/items/${this.itemIdTarget.value}`)
         .then(json => {
           this.labelTarget.classList.remove('has-text-weight-bold')
         });
  }
}
