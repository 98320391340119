import { Controller } from "@hotwired/stimulus"
import axios from "../lib/axios";

export default class extends Controller {
  static targets = ["container", "master"]

  changeMaster(event) {
    this.containerTarget.querySelectorAll('.client-master').forEach((el, i) => {
      el.value = this.masterTarget.value
      el.dispatchEvent(new Event('change'))
    })
  }
}
