import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default class extends Controller {
  static targets = ["type", "message"]

  connect() {
    const notyf = new Notyf()
    notyf.open({ type: this.typeTarget.value, message: this.messageTarget.value })
  }
}
