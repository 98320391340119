import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  initialize() {
    let maskOpts = {
      overwrite: true,
      autofix: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2
        }
      }
    };
    document.querySelectorAll('.mask-time').forEach((el, i) => { IMask(el, maskOpts) })
  }
}
