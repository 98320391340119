import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  navigate(event) {
    const ref = event.target.querySelector('a');
    if (!ref) { return };

    Turbo.visit(ref.href);
  }
}
