import { application } from "./application"

import ColorPicker from 'stimulus-color-picker'
application.register('color-picker', ColorPicker)

import AutocompleteController from "./autocomplete_controller.js"
application.register("autocomplete", AutocompleteController)

import CalendarController from "./calendar_controller.js"
application.register("calendar", CalendarController)

import DatepickerController from "./datepicker_controller.js"
application.register("datepicker", DatepickerController)

import DropdownsController from "./dropdowns_controller.js"
application.register("dropdowns", DropdownsController)

import EventFormController from "./event_form_controller.js"
application.register("event-form", EventFormController)

import FiltersController from "./filters_controller.js"
application.register("filters", FiltersController)

import FlashController from "./flash_controller.js"
application.register("flash", FlashController)

import MasterScheduleController from "./master_schedule_controller.js"
application.register("master-schedule", MasterScheduleController)

import ModalController from "./modal_controller.js"
application.register("modal", ModalController)

import RangeSliderController from "./range_slider_controller.js"
application.register("range-slider", RangeSliderController)

import SidebarController from "./sidebar_controller.js"
application.register("sidebar", SidebarController)

import SupplyIntervalsController from "./supply_intervals_controller.js"
application.register("supply-intervals", SupplyIntervalsController)

import TabsController from "./tabs_controller.js"
application.register("tabs", TabsController)

import TimeInputsController from "./time_inputs_controller.js"
application.register("time-inputs", TimeInputsController)

import ReportItemsController from "./report_items_controller.js"
application.register("report-items", ReportItemsController)

import ServiceItemsController from "./service_items_controller.js"
application.register("service-items", ServiceItemsController)

import ServicesController from "./services_controller.js"
application.register("services", ServicesController)

import ServicesGroupsController from "./services_groups_controller.js"
application.register("services-groups", ServicesGroupsController)

import FileInputsController from "./file_inputs_controller.js"
application.register("file-inputs", FileInputsController)

import PackFormController from "./pack_form_controller.js"
application.register("pack-form", PackFormController)
