import { Controller } from "@hotwired/stimulus"
import moment from "moment"
import IMask from "imask"

export default class extends Controller {
  static targets = ["from", "duration", "till"]

  initialize() {
  	let maskOpts = {
  		overwrite: true,
		  autofix: true,
		  mask: 'HH:MM',
		  blocks: {
		    HH: {
		      mask: IMask.MaskedRange,
		      placeholderChar: 'HH',
		      from: 0,
		      to: 23,
		      maxLength: 2
		    },
		    MM: {
		      mask: IMask.MaskedRange,
		      placeholderChar: 'MM',
		      from: 0,
		      to: 59,
		      maxLength: 2
		    }
		  }
  	};
  	document.querySelectorAll('.mask-time').forEach((el, i) => { IMask(el, maskOpts) })
  }

  update_till() {
    let start = moment(this.fromTarget.value, 'HH:mm')
    const dur = this.durationTarget.value
    start.add(dur, 'HH:mm')

   	this.tillTarget.innerHTML = start.format('HH:mm')
  }
}
