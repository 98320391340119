import { Controller } from "@hotwired/stimulus"
import noUiSlider from "nouislider"

export default class extends Controller {
  static targets = ["slider", "from", "to"]

  connect() {
    noUiSlider.create(this.sliderTarget, {
      start: [10, 18],
      connect: true,
      step: 1,
      range: {
        'min': 9,
        'max': 24
      },
      pips: {
        mode: 'steps',
        density: 6,
        filter: ()=> { return 1 }
      }
    })
  }
}
