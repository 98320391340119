import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import ruLocale from '@fullcalendar/core/locales/ru';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import consumer from "../channels/consumer";
import moment from "moment";

export default class extends Controller {
  static targets = ["container", "currentDate", "venue"];

  connect() {
    const venue = this.data.get("venueId");
    const token = this.data.get("token");

    const date = moment(this.currentDateTarget.value, "DD.MM.YYYY");

    const calendar = new Calendar(this.containerTarget, {
      plugins: [timeGridPlugin, interactionPlugin],
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      initialView: 'timeGridDay',
      locale: ruLocale,
      initialDate: date.format("YYYY-MM-DD"),
      allDaySlot: false,
      slotMinTime: "08:00:00",
      snapDuration: "00:30:00",
      timeZone: "UTC",
      selectable: false,
      editable: false,
      titleFormat: { weekday: 'long', month: 'long', day: 'numeric' },
      headerToolbar: {
        left: false,
        center: 'title',
        right: false
      },
      eventDurationEditable: false,
      events: {
        url: `/app/${token}/schedule/events.json`
      },
      eventContent: function(arg) {
        let timing = document.createElement('div');
        timing.classList.add('fc-event-time');
        timing.innerHTML = `${moment(arg.event.start).utc().format('HH:mm')} - ${moment(arg.event.end).utc().format('HH:mm')}`

        const props = arg.event.extendedProps;

        let badge = document.createElement('span');
        badge.classList.add('tag', 'fc-room-badge');
        badge.innerHTML = props.roomName;
        badge.style.backgroundColor = props.roomBadgeColor;
        badge.style.display = props.roomBadgeLabel ? 'inline' : 'none';

        let title = document.createElement('span');
        title.classList.add('fc-event-title');
        title.innerHTML = arg.event.title

        return { domNodes: [timing, title, badge] }
      }
    });

    calendar.render();

    this._calendar = calendar

    consumer.subscriptions.create(
      { channel: "CalendarEventsChannel", venue_id: venue },
      {
        received() {
          calendar.refetchEvents();
        }
      }
    );
  }

  prev(event) {
    event.preventDefault()
    this._calendar.prev()
    const picker = this.currentDateTarget.datepicker
    picker.setDate(this._calendar.getDate())
  }

  next(event) {
    event.preventDefault()
    this._calendar.next()
    const picker = this.currentDateTarget.datepicker
    picker.setDate(this._calendar.getDate())
  }

  changeVenue() {
    const location = window.location.toString();
    const oldId = this.data.get("venueId");
    const venueId = this.venueTarget.value;

    window.location = location.replace(`/${oldId}/`, `/${venueId}/`)
  }

  goToDate(event) {
    const selected = moment(event.detail.date.toLocaleDateString(),
                       "DD.MM.YYYY").format("YYYY-MM-DD")

    this._calendar.gotoDate(selected)
  }
}
