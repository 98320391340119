import { Controller } from "@hotwired/stimulus"
import noUiSlider from "nouislider"
import axios from "../lib/axios";

export default class extends Controller {
  static targets = ["checkbox", "slider", "label"]
  static values = {
    date: String,
    from: Number,
    to: Number,
    token: String
  }

  connect() {
    const slider = noUiSlider.create(this.sliderTarget, {
      start: [this.fromValue, this.toValue],
      connect: true,
      step: 1,
      range: {
        'min': 9,
        'max': 24
      },
      pips: {
        mode: 'steps',
        density: 6,
        filter: ()=> { return 1 }
      }
    })

    slider.on("set", (values)=> {
      this.fromValue = values[0]
      this.toValue = values[1]
      this.commitInterval()
    })
  }

  toggle(event) {
    if (event.target.checked) {
      this.sliderTarget.classList.remove("is-hidden")
      this.commitInterval()
    } else {
      this.sliderTarget.classList.add("is-hidden")
      this.removeInterval()
    }
  }

  commitInterval() {
    const params = {
      date: this.dateValue,
      from: this.fromValue,
      to: this.toValue
    }

    axios.post(`/app/${this.tokenValue}/intervals`, params)
         .then(json => {
           this.labelTarget.classList.add('has-text-weight-bold')
         });
  }

  removeInterval() {
    axios.post(`/app/${this.tokenValue}/intervals/remove`, { date: this.dateValue })
         .then(json => {
           this.labelTarget.classList.remove('has-text-weight-bold')
         });
  }
}
