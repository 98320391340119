import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tab", "tabPanel" ]

  get current() {
    if (this.data) {
      return this.data.get('current')
    }
  }

  set current(value) {
    this.data.set("current", value)
  }
 
  initialize() {
    this.showTab()
  }

  goto(e){
    this.current = e.target.parentNode.getAttribute('data-tabs-tab')
    this.showTab(this.current)
  }

  showTab() {
    if (this.current == null) {
      this.tabPanelTargets[0].classList.remove('is-hidden')
      this.tabTargets[0].classList.add('is-active')
    }
    else {
      this.tabPanelTargets.forEach((el, i) => {
        if(el.id == this.current){
          el.classList.remove('is-hidden')
          el.classList.add('is-active')
        } else {
          el.classList.add('is-hidden')
          el.classList.remove('is-active')
        }
      })

      this.tabTargets.forEach((el, i) => {
        if(el.getAttribute('data-tabs-tab') == this.current){
          el.classList.add('is-active')
        } else {
          el.classList.remove('is-active')
        }
      })
    }
  }
}
